


















































// Import vendors ----------------------------------------------------------------------------------
import { ref, computed, defineComponent, onBeforeUnmount } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormObserver, useFormFieldText } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import AuthWrapper from '@/components/auth/AuthWrapper.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthResetPasswordSendCode',
  components: { AuthWrapper, FormFieldText },
  props: {
    resetPasswordService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rAuthWrapper = ref(null);

    const observer = useFormObserver();

    const username = useFormFieldText<string>({
      key: 'username',
      type: 'email',
      value: '',
      rules: {
        required: true,
        email: true
      }
    });

    function submit() {
      properties.resetPasswordService.send({
        type: 'SEND_CODE',
        data: {
          username: username.value.toLowerCase()
        }
      });
    }

    const isErrored = computed(() =>
      [{ sendingCode: 'failure' }].some((element) =>
        properties.resetPasswordService.state.value.matches(element)
      )
    );

    const isDisabled = computed(() =>
      [{ sendingCode: 'processing' }, 'waitingUserCompletion'].some((element) =>
        properties.resetPasswordService.state.value.matches(element)
      )
    );

    const isLoading = computed(() =>
      [{ sendingCode: 'processing' }].some((element) =>
        properties.resetPasswordService.state.value.matches(element)
      )
    );

    // Delete error message before unmount
    onBeforeUnmount(() => {
      /* if (isErrored.value) {
        properties.authService.send({
          type: 'RESET',
        });
      } */
    });

    function skipStep() {
      properties.resetPasswordService.send({
        type: 'SKIP_SEND'
      });
    }

    return {
      // References
      rAuthWrapper,
      // Form observer
      observer,
      // Form fields
      username,
      // Actions
      submit,
      skipStep,
      // Flags
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
