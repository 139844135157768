









// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onBeforeUnmount } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import components -------------------------------------------------------------------------------
import AuthResetPasswordSubmit from '@/components/auth/resetPassword/AuthResetPasswordSubmit.vue';
import AuthResetPasswordSendCode from '@/components/auth/resetPassword/AuthResetPasswordSendCode.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthResetPassword',
  components: { AuthResetPasswordSendCode, AuthResetPasswordSubmit },
  props: {
    authService: {
      type: Object,
      required: true
    }
  },
  setup(properties, { root }) {
    // Extract "reset password" service from "auth" parent
    const resetPasswordService = useService(
      properties.authService.service.value.children.get('resetPassword')
    );

    const isFirstStep = computed(() =>
      ['idle', 'sendingCode'].some((element) => resetPasswordService.state.value.matches(element))
    );

    const subscription = resetPasswordService.service.value.subscribe((state) => {
      if (state.matches('success')) {
        root.$router.push({ name: 'auth--sign-in', params: { resetPassword: 'true' } });
      }
    });

    // Reset service when leaving
    onBeforeUnmount(() => {
      resetPasswordService.send({
        type: 'RESET'
      });
      subscription.unsubscribe();
    });

    return {
      // Services
      resetPasswordService,
      // Flags
      isFirstStep
    };
  }
});
